<template>
  <div>
    <InnerDetail>
      <template v-slot:title>
        {{ $route.meta.title }}
      </template>
      <template v-slot:detail>
        <div class="detail-content">
          <p class="sub-title">项目概述：</p>
          <span>
          中正易驾是一款微信小程序，为机动车驾驶人提供了理论学时学习上报学习时长的功能，分为首页、理论学习、个人中心三大板块，拥有学习视频，刷题练习、模拟考试、以及用户信息、学员各科目学时进度情况等功能，对接了计时平台的理论学时上传功能，签到人脸识别通过后进入学习，期间会有定时过程拍照，人脸识别签退后上传学时到计时平台，提供了完成线上理论学习的功能加快了驾驶人理论学习速度，更有查询自己各科目学时时长情况，使驾驶人能够及时的查询学习进度和待完成科目。
          </span>
        </div>
        <div class="detail-img">
          <div v-for="i in 6" :key="i">
            <img :src="require(`@/assets/img/product/yijia${i}.jpg`)" alt="">
          </div>
        </div>
      </template>
    </InnerDetail>
  </div>
</template>
<script>
import InnerDetail from "@/views/product/components/InnerDetail.vue";

export default {
  name: 'zhongzhengyijia',
  components: {InnerDetail},
}
</script>
